if (!Element.prototype.matches){
	Element.prototype.matches =
	Element.prototype.msMatchesSelector ||
	Element.prototype.webkitMatchesSelector;
}
if (!Element.prototype.closest){
	Element.prototype.closest = function(s){
        var el = this;
        do {
            if (Element.prototype.matches.call(el, s)) return el;
            el = el.parentElement || el.parentNode;
        } while (el !== null && el.nodeType === 1);
        return null;
	};
}

var hasLeftNav = false;

window.addEventListener('load', function(){
    var headToggles = document.querySelectorAll('#agghead a[aria-expanded]');
    for (var i = 0; i != headToggles.length; ++i){
        headToggles[i].addEventListener('mouseover', function(){
            hasLeftNav = false;
            this.setAttribute('aria-expanded', 'true');
            if (this.nextElementSibling && !this.parentElement.classList.contains('nav-li')){
                this.nextElementSibling.classList.add('uk-open');
            }
        });
        headToggles[i].addEventListener('mouseout', function(){
            var that = this;
            hasLeftNav = true;
            setTimeout(function(){
                if (hasLeftNav){
                    that.setAttribute('aria-expanded', 'false');
                    if (that.nextElementSibling && !that.parentElement.classList.contains('nav-li')){
                        that.nextElementSibling.classList.remove('uk-open');
                        // that.nextElementSibling.setAttribute('style', '');
                    }
                }
            },10);
        });
        if (headToggles[i].nextElementSibling){
            headToggles[i].nextElementSibling.addEventListener('mouseover', function(){
                hasLeftNav = false;
            });
            headToggles[i].nextElementSibling.addEventListener('mouseout', function(){
                hasLeftNav = true;
                var event = new Event('mouseout');
                this.previousElementSibling.dispatchEvent(event);
            });
        }

    }

    var tabs = document.querySelectorAll('#agghead .uk-tab li');
    for (var x = 0; x != tabs.length; ++x){
        tabs[x].addEventListener('click', function(){
            var ul = this.closest('ul');
            var checks = ul.querySelectorAll('input.uk-checkbox');
            if (checks){
                for (var i = 0; i != checks.length; ++i){
                    checks[i].checked = false;
                }
                var myCheck = this.querySelector('input.uk-checkbox');
                if (myCheck){
                    myCheck.checked = true;
                }
            }
            else {
                var siblings = ul.querySelectorAll('li');
                for (var i = 0; i != siblings.length; ++i){
                    siblings[i].classList.remove('uk-active');
                }
                this.classList.add('uk-active');
            }
            var nextSibling = ul.nextElementSibling;
            var index = Array.from(this.parentNode).indexOf(this);
            while (nextSibling){
                if (nextSibling.classList.contains('uk-switcher')){
                    var panels = nextSibling.querySelectorAll('li');
                    for (var y = 0; y != panels.length; ++y){
                        if (y == index){
                            panels[y].classList.add('uk-active');
                        }
                        else {
                            panels[y].classList.remove('uk-active');
                        }
                    }
                    nextSibling = null;
                }
                nextSibling = nextSibling.nextElementSibling;
            }
        });
    }
    var closeBtn = document.querySelector('#agghead .uk-position-top-right');
    if (closeBtn){
        closeBtn.addEventListener('click', function(){
            hasLeftNav = true;
            var event = new Event('mouseout');
            document.querySelector('#agghead .uk-tab li').dispatchEvent(event);
        });
    }

    var tabs = document.querySelectorAll('#agghead .model--Category label');
    for (var i = 0; i != tabs.length; ++i){
        tabs[i].addEventListener('click', function(){
            var myLi = this.parentNode;
            var myDiv = this.closest('.vehicle-wrap');
            var index = Array.from(myLi.parentNode.children).indexOf(myLi);
            var tabpanels = myDiv.querySelectorAll('.vehicle-filter');
            for (var x = 0; x != tabpanels.length; ++x){
                tabpanels[x].setAttribute('aria-hidden', x == index ? 'false' : 'true');
            }
        });
    }

    var mobileToggle = document.querySelector('#agghead .uk-navbar-toggle.navbar-toggler.sitedraw');
    var closeToggle = document.querySelector('#agghead [uk-toggle="target: #siteDraw"]');
    var popup = document.getElementById('siteDraw');
    if (mobileToggle && popup){
        mobileToggle.addEventListener('click', function(e){
            e.preventDefault();
            popup.classList.add('uk-open');
            document.querySelector('html').classList.add('uk-modal-page');
        });
        closeToggle.addEventListener('click', function(e){
            e.preventDefault();
            popup.classList.remove('uk-open');
            document.querySelector('html').classList.remove('uk-modal-page');
        });
    }

    var accordions = document.querySelectorAll('#agghead .uk-accordion-title');
    for (var i = 0; i != accordions.length; ++i){
        accordions[i].addEventListener('click', function(e){
            e.preventDefault();
            var isOpen = this.getAttribute('aria-expanded') == 'true';
            var parent = this.closest('.uk-accordion');
            var siblings = parent.querySelectorAll('.uk-accordion-title');
            var panels = parent.querySelectorAll('.uk-accordion-content');
            for (var x = 0; x != siblings.length; ++x){
                siblings[x].setAttribute('aria-expanded', 'false');
            }
            for (var x = 0; x != panels.length; ++x){
                panels[x].setAttribute('hidden','');
            }
            this.setAttribute('aria-expanded', isOpen ? 'false' : 'true');
            var div = this.nextElementSibling;
            if (div){
                if (isOpen){
                    div.setAttribute('hidden', '');
                }
                else {
                    div.removeAttribute('hidden');
                }
            }
        })
    }

    var mobileVehiclesBtns = document.querySelectorAll('#siteDraw a[href="#offcanvas-models"]');
    var mobileVehiclesMenu = document.getElementById('offcanvas-models');
    if (mobileVehiclesBtns && mobileVehiclesMenu){
        var mobileVehiclesMenuCloseBtn = mobileVehiclesMenu.querySelector('a[data-uk-toggle="target: #offcanvas-models"]')
        for (let i = 0; i != mobileVehiclesBtns.length; ++i){
            mobileVehiclesBtns[i].addEventListener('click', function(e){
                e.preventDefault();
                mobileVehiclesMenu.classList.add('uk-open');
                mobileVehiclesMenu.setAttribute('tabindex', '0');
            });
        }

        mobileVehiclesMenuCloseBtn.addEventListener('click', function(e){
            e.preventDefault();
            mobileVehiclesMenu.classList.remove('uk-open');
            mobileVehiclesMenu.setAttribute('tabindex', '-1');
            if (closeToggle){
                closeToggle.click();
            }
        })
    }
});
